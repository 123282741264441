// src/hooks/useAxios.js
import { useEffect, useMemo } from 'react';
import axios from 'axios';
import useAuth from './useAuth';

const useAxios = () => {
  const { tokenData, setIsLoggedIn, setUserData, setTokenData } = useAuth();
  //const baseURL = 'http://localhost:5000/api/v1';
  const baseURL = 'https://nimbusbackend.skarbol.tech/api/v1';

  const axiosPublic = useMemo(() => {
    const instance = axios.create({ baseURL });
    return instance;
  }, []);


  const axiosUser = useMemo(() => {
    const instance = axios.create({ baseURL });

    instance.interceptors.request.use(
      (config) => {
        if (tokenData) {
          const accessToken = tokenData?.accessToken?.token;
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Handle token expiration or unauthorized access
          setIsLoggedIn(false);
          setUserData(null);
          setTokenData(null);
          localStorage.removeItem('userData');
          localStorage.removeItem('tokenData');
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [tokenData, setIsLoggedIn, setUserData, setTokenData]);

  return { axiosPublic, axiosUser };
};

export default useAxios;
