import React, { useState } from "react";

export default function ForgotPassword({ setView, axiosPublic }) {
  const [reference, setReference] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setReference(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosPublic.post("/auth/reset-password", { reference });
      setMessage(response.data.message);
      setError("");

      // Set the view to "verifyOTP" on success
      setView("verify-otp-reset");
    } catch (error) {
      setError(
        error.response?.data?.message || "Failed to send OTP. Please try again."
      );
      setMessage("");
    }
  };

  return (
    <div className="p-8 max-w-md w-full space-y-8 pt-8 sm:pt-20 bg-[#007055]">
      <h2 className="mb-6 text-3xl font-bold">OTP Verification</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label className="block">Email or Contact Number</label>
          <input
            type="text"
            placeholder="Enter email or contact number"
            value={reference}
            onChange={handleChange}
            className="w-full py-1 bg-inherit border-b border-white text-white"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-gray-100 rounded text-black hover:bg-gray-500 hover:text-white duration-200"
        >
          Send OTP
        </button>
        {message && <p className="mt-4 text-green-500">{message}</p>}
        {error && <p className="mt-4 text-red-500">{error}</p>}
        <p className="mt-4 text-center font-light">
          Remembered your password{" "}
          <button
            type="button"
            onClick={() => setView("login")}
            className="text-blue-500 sm:text-white hover:text-blue-500 font-bold duration-300"
          >
            Log In
          </button>
        </p>
      </form>
    </div>
  );
}
