import React from 'react';
import { useNavigate } from 'react-router-dom';

const UnAuthorized = () => {

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-100 text-red-800">
      <h1 className="text-6xl font-bold mb-4">403</h1>
      <p className="text-2xl mb-4">Unauthorized Access</p>
      <p className="text-lg mb-4">
        You do not have permission to view this page.
      </p>
      <button
        onClick={goBack}
        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
      >
        Go Back
      </button>
    </div>
  );
};

export default UnAuthorized;
