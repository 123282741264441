import React from "react";
import DoctorLayout from "../../layouts/doctorLayout";
import ChatComponent from "../../components/chat/chatComponent";


export default function Chat() {
  return (
    <DoctorLayout>
      <div className="w-full h-screen py-8">
        <ChatComponent />
      </div>
    </DoctorLayout>
  );
}
