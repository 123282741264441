import React, {useState} from "react";
import PlaceAppointmentModal from "./PlaceAppointmentModal";
import SendMessageModal from "../SendMessageModal";

export default function DoctorCard({ doctor }) {

  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const openAppointmentModal = (doctor) => {
    setSelectedDoctor(doctor);
    setIsAppointmentModalOpen(true);
  };

  const openMessageModal = (doctor) => {
    setSelectedDoctor(doctor);
    setIsMessageModalOpen(true);
  };

  const closeModals = () => {
    setIsAppointmentModalOpen(false);
    setIsMessageModalOpen(false);
    setSelectedDoctor(null);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg m-4 w-64">

      <div className="mt-0 pt-4">
        <h5 className="text-lg font-bold text-center">{`${doctor.user_id.name}`}</h5>
        <p className="text-center text-[#4f4f4f]">
          {doctor.specializations.map(spec => spec.name).join(", ")}
        </p>
        <p className="text-sm text-gray-500 text-center">
          {doctor.qualifications.map(spec => spec.name).join(", ")}
        </p>

        <div className="flex items-center justify-center w-full pt-4">
          {[...Array(5)].map((_, i) => (
            <svg
              key={i}
              className={`w-4 h-4 ${
                i < doctor.average_rating ? "text-[#008A73]" : "text-gray-300"
              }`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 15l-5.878 3.09 1.122-6.545L.488 6.41l6.564-.955L10 0l2.948 5.455 6.564.955-4.756 4.135 1.122 6.545z"/>
            </svg>
          ))}
        </div>
      </div>

      <div className="flex justify-center gap-2 py-4 px-4">
        <button
          onClick={() => openAppointmentModal(doctor)}
          className="bg-[#007055] text-white rounded-lg px-2 py-2 focus:outline-none hover:bg-[#008A73]">
          Appointment
        </button>
        <button onClick={() => openMessageModal(doctor)}
                className="bg-black text-white rounded-lg px-2 py-2 focus:outline-none hover:bg-[#007055]">
          Message
        </button>
      </div>

      {selectedDoctor && (
        <>
          <PlaceAppointmentModal
            doctor={selectedDoctor}
            isOpen={isAppointmentModalOpen}
            onClose={closeModals}
          />
          <SendMessageModal
            doctor={selectedDoctor}
            isOpen={isMessageModalOpen}
            onClose={closeModals}
          />
        </>
      )}
    </div>
  );
}
