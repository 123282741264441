import React, {useState, useEffect, useRef} from "react";
import useAxios from "../../hooks/useAxios";
import useAuth from "../../hooks/useAuth";
import {MdChat} from "react-icons/md";
import io from "socket.io-client";

export default function ChatUI() {
  //Define component states
  const [messageText, setMessageText] = useState("");
  const [chatRooms, setChatRooms] = useState([]);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalMessagePages, setTotalMessagePages] = useState(1);
  const [isMessagesFetching, setIsMessagesFetching] = useState(false);
  const [initialScrolled, setInitialScrolled] = useState(false);
  const [newMessageAdded, setNewMessageAdded] = useState(false);

  const {axiosUser} = useAxios();
  const {userData, tokenData} = useAuth();
  const socketRef = useRef(null);
  const messagesContainerRef = useRef(null);

  // Initiate socket.io instance and store in socketRef
  useEffect(() => {
    if (selectedChatRoom && tokenData.accessToken.token) {

      //const socketServerHost = "http://localhost:5000";
      const socketServerHost = "https://nimbusbackend.skarbol.tech";

      // Initialize or reconnect socket when room selected
      socketRef.current = io(socketServerHost, {
        auth: {
          token: tokenData.accessToken.token,
          roomId: selectedChatRoom,
        },
      });

      // Event listener for incoming messages
      socketRef.current.on("message", async (messageObject) => {
        await setMessages((prevMessages) => [...prevMessages, messageObject]);
        setNewMessageAdded(true);
      });

      // Handle disconnect or cleanup
      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [selectedChatRoom, tokenData, messages]);

  // Fetch chat rooms
  useEffect(() => {

    const fetchChatRooms = async () => {
      try {
        const response = await axiosUser.get(
          "/chats?page=1&limit=15&sortBy=createdAt:desc"
        );
        setChatRooms(response.data.data);
      } catch (error) {
        console.error("Failed to fetch chat rooms", error);
      }
    };

    fetchChatRooms();
  }, [axiosUser]);


  useEffect(() => {
    const handleScroll = async () => {
      if (messagesContainerRef.current.scrollTop === 0 &&
        !isMessagesFetching && selectedChatRoom && currentPage < totalMessagePages) {

        setIsMessagesFetching(true);

        try {

          const response = await axiosUser.get(`/chats/${selectedChatRoom}?page=${Number(currentPage) + 1}&limit=15&sortBy=createdAt:desc`);
          const newMessages = response?.data?.data.reverse();

          setMessages((prevMessages) => [...newMessages, ...prevMessages]);

          setCurrentPage((prevPage) => Number(prevPage) + 1);
          setTotalMessagePages(response?.data?.pages);

        } catch (error) {
          console.error("Failed to fetch previous messages", error);
        } finally {
          setIsMessagesFetching(false);
        }
      }
    };

    const messagesContainer = messagesContainerRef.current;
    messagesContainer.addEventListener("scroll", handleScroll);

    return () => {
      messagesContainer.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, isMessagesFetching, selectedChatRoom, axiosUser]);


  // Scroll the message container initially
  useEffect(() => {
    if(!initialScrolled && Number(currentPage) === 1){
      scrollMessageContainer();
      setInitialScrolled(true);
    }
  }, [messages]);

  // Scroll message container whenever messages state changes
  useEffect(() => {
    if (newMessageAdded) {
      scrollMessageContainer();
      setNewMessageAdded(false);
    }
  }, [messages, newMessageAdded]);


  // Handle chat room click
  const handleChatRoomClick = async (roomId) => {
    try {

      const response = await axiosUser.get(
        `/chats/${roomId}?page=1&limit=15&sortBy=createdAt:desc`
      );

      const messagesData = response?.data?.data;
      setMessages(messagesData.reverse());
      setSelectedChatRoom(roomId);
      setCurrentPage(response?.data?.page);
      setTotalMessagePages(response?.data?.pages);
      setInitialScrolled(false);

    } catch (error) {
      console.error("Failed to fetch chat room messages", error);
    }
  };

  const handleInputChange = (event) => {
    setMessageText(event.target.value);
  };

  const handleSend = () => {
    if (messageText.trim() === "") return;
    // Emit message through the socket
    socketRef.current.emit("message", messageText);
    setMessageText("");
  };

  const scrollMessageContainer = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  return (
    <div className="bg-white max-w-7xl mx-auto h-[90%] flex flex-col md:flex-row">
      {/* Chatrooms list box */}

      <div className="w-full md:w-1/3 p-4 space-y-4">
        <h2 className="text-xl font-bold flex items-center">
          <MdChat className="mr-2"/> Chat Rooms
        </h2>
        <div className="space-y-2">
          {chatRooms.map((chatRoom) => (
            <div
              key={chatRoom.id}
              className={`p-4 rounded-lg cursor-pointer ${
                selectedChatRoom === chatRoom.id
                  ? "bg-[#B3B3B3] text-dark"
                  : "bg-gray-100"
              }`}
              onClick={() => handleChatRoomClick(chatRoom.id)}
            >
              {userData.role === "doctor"
                ? chatRoom.patient.name
                : chatRoom.doctor.name}
            </div>
          ))}
        </div>
      </div>

      {/* Chat box */}
      <div className="w-full md:w-2/3 flex flex-col p-3">
        <div className="bg-[#A6CAC4] overflow-hidden h-full flex flex-col">
          <div ref={messagesContainerRef} className="p-2 sm:p-8 space-y-4 flex-1 overflow-y-auto hide-scrollbar">
            {!selectedChatRoom ? (
              <div className="flex justify-center items-center h-full text-gray-500">
                Click chatrooms to start chatting
              </div>
            ) : (
              <div className="flex flex-col gap-4 justify-start h-full text-sm sm:text-[14px] font-bold">
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`rounded-lg p-3 max-w-xs ${
                      message.sent_by.id === userData._id
                        ? "self-end bg-[#008A73]"
                        : "self-start bg-white"
                    }`}
                  >
                    <span
                      className={`${
                        message.sent_by.id === userData._id
                          ? "text-white"
                          : "text-dark"
                      }`}
                    >
                      {message.message}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
          {selectedChatRoom && (
            <div className="p-4 flex items-end space-x-2">
              <input
                type="text"
                value={messageText}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type a message"
                className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none"
              />
              <button
                onClick={handleSend}
                className="bg-[#007055] text-white rounded-lg px-6 py-2 focus:outline-none hover:bg-green-600"
              >
                Send
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
