import React, {useEffect} from "react";
import { useState } from "react";
import TimeSelectionComponent from "../../components/doctor/availabilitySelection";
import DoctorLayout from "../../layouts/doctorLayout";
import useAxios from "../../hooks/useAxios";

export default function DoctorProfileDoctor() {

  const {axiosUser} = useAxios();
  //toggle start
  const [isActive, setIsActive] = useState(false);
  const [doctorData, setDoctorData] = useState({});

  useEffect(() => {

    const fetchDoctorProfile = async () => {
      try {
        const response = await axiosUser.get(
            "/doctors/profile"
        );

        setDoctorData(response.data)

        if(response.data.availability_status === "available") {
          setIsActive(true);
        }

      } catch (error) {
        console.error("Failed to fetch doctor profile", error);
      }
    };

    fetchDoctorProfile();
  }, [axiosUser]);

  const handleToggle = async () => {

    try{

      const response = await axiosUser.put(
          "/doctors/status"
      );
      setIsActive(!isActive);
      alert('Status updated successfully')

    } catch (error) {
      console.error("Failed to update doctor status", error);
    }
  };

  //toggle ends

  const doctor = {
    image: "/img/Cartoon.png",
  };
  return (
    <DoctorLayout>
      <div className="w-full p-4 sm:p-8 sm:flex items-start gap-8">
        <section className="sm:w-3/5">
          <div className="bg-white rounded-lg shadow">
            <div className="flex items-center justify-end p-4">
              <div className="flex items-center space-x-3">
                <span className="font-medium">Status</span>
                <button
                  onClick={handleToggle}
                  className="w-12 h-6 rounded-full p-1 bg-gray-300"
                >
                  <div
                    className={`transform transition-transform duration-300 ${
                      isActive
                        ? "translate-x-6 bg-[#35B707]"
                        : "bg-white translate-x-0"
                    } w-4 h-4 rounded-full shadow-md`}
                  ></div>
                </button>
              </div>
            </div>
            <div className="flex items-center justify-start gap-6 px-4 sm:px-8 pb-4 sm:pb-8">
              <div className="">
                <img
                  src={doctor.image}
                  alt={doctorData.user_id?.name}
                  height={180}
                  width={180}
                  className="object-cover rounded-lg"
                />
              </div>

              <div className="">
                <h3 className="text-[30px] font-bold">{doctorData.user_id?.name}</h3>
                <p className="text-base py-2">
                  {Array.isArray(doctorData.specializations) && doctorData.specializations.map((specialization, index) => (
                      <span key={specialization}>
                        {specialization.name}{index < doctorData.specializations.length - 1 && ', '}
                      </span>
                  ))}
                </p>

                <div className="flex items-center">
                  {[...Array(5)].map((_, i) => (
                      <svg
                          key={i}
                          className={`w-4 h-4 ${
                              i < doctorData.average_rating ? "text-[#008A73]" : "text-gray-300"
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.878 3.09 1.122-6.545L.488 6.41l6.564-.955L10 0l2.948 5.455 6.564.955-4.756 4.135 1.122 6.545z" />
                    </svg>
                  ))}
                  <span className="ml-2">({doctorData.average_rating})</span>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8 bg-white p-4 sm:p-8 mb-8 rounded-lg shadow">
            <h2 className="text-xl sm:text-[34px] font-bold pb-2">Biography</h2>
            <p className="text-sm sm:text-[18px] pb-4">{doctorData.biography}</p>

            <h2 className="text-lg sm:text-[24px] font-bold pb-1 sm:pb-2">
              Qualifications
            </h2>

            <p className="text-sm sm:text-[18px] pb-4">
              {Array.isArray(doctorData.qualifications) && doctorData.qualifications.map((qualification, index) => (
                  <span key={qualification._id}>
                  {qualification.name}{index < doctorData.qualifications.length - 1 && ', '}
                  </span>
              ))}
            </p>


            <h2 className="text-lg sm:text-[24px] font-bold pb-1 sm:pb-2">
              Years of Experience
            </h2>
            <p className="text-sm sm:text-[18px] pb-4">
              {doctorData.years_of_experience} Years
            </p>
          </div>
        </section>
        <section className="sm:w-2/5 bg-white px-2 rounded-lg shadow">
          <TimeSelectionComponent
              DoctorAvailability={doctorData.availability}
          />
        </section>
      </div>
    </DoctorLayout>
  );
}
