import React, { useState } from 'react';
import useAxios from "../../hooks/useAxios";

const PlaceAppointmentModal = ({ doctor, isOpen, onClose }) => {

  const [dateTime, setDateTime] = useState('');
  const { axiosUser } = useAxios();

  const handleAppointmentBooking = async () => {
    try {
      const response = await axiosUser.post('/appointments', {
        doctor: doctor.user_id._id || doctor.user_id.id,
        date_time: dateTime,
        status: 'pending'
      });
      alert('Appointment booked successfully');
      onClose();
    } catch (error) {
      console.error('Error booking appointment:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-bold">Book Appointment with Dr. {doctor.user_id.name}</h2>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-4">
          <div className="mb-4">
            <label htmlFor="dateTime" className="font-medium block mb-2">Date and Time</label>
            <input
              type="datetime-local"
              id="dateTime"
              className="w-full p-2 rounded-md bg-[#F6F6F6]"
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end p-4 border-t">
          <button
            onClick={onClose}
            className="bg-black text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600">
            Close
          </button>
          <button
            onClick={handleAppointmentBooking}
            className="bg-[#007055] hover:bg-[#008A73] text-white px-4 py-2 rounded-lg">
            Book Appointment
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlaceAppointmentModal;
