import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext(undefined);
/**
 * Initializes the authentication provider with context states and sets stored user and token data if available.
 *
 * @param {void}
 * @return {JSX.Element} The provider component with context value and children
 */
export const AuthProvider = ({ children }) => {

  // Initiate context states
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setStorageData();
    setLoading(false);
  }, []);

  /**
   * Sets stored user and token data if available.
   *
   * @param {void}
   * @return {void}
   */
  const setStorageData = () => {

    const storedUserData = localStorage.getItem('userData');
    const storedTokenData = localStorage.getItem('tokenData');

    if (storedUserData && storedTokenData) {
      setIsLoggedIn(true);
    }

    if (!userData && storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }

    if (!tokenData && storedTokenData) {
      setTokenData(JSON.parse(storedTokenData));
    }
  };

  const contextValue = {
    isLoggedIn,
    setIsLoggedIn,
    userData,
    setUserData,
    tokenData,
    setTokenData,
    loading
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};