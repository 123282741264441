import React from "react";
import ChatComponent from "../../components/chat/chatComponent";
import PatientLayout from "../../layouts/patientLayout";

export default function Chat() {
  return (
    <PatientLayout>
      <div className="w-full h-[90vh] py-2">
        <ChatComponent />
      </div>
    </PatientLayout>
  );
}
