import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Swal from "sweetalert2";

export default function Login({ setView, axiosPublic, useCase }) {
  const [loginData, setLoginData] = useState({ login: "", password: "" });
  const { setIsLoggedIn, setUserData, setTokenData } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosPublic.post("/auth/login", loginData);
      const { user, token } = response.data;

      // Update the context with user and token data
      setIsLoggedIn(true);
      setUserData(user);
      setTokenData(token);

      // Save to localStorage
      localStorage.setItem("userData", JSON.stringify(user));
      localStorage.setItem("tokenData", JSON.stringify(token));

      // Navigate to the appropriate dashboard
      if (user.role === "patient") {
        navigate("/patient/dashboard");
      } else if (user.role === "doctor") {
        navigate("/doctor/dashboard");
      } 
    } catch (error) {
      if (error.response?.status === 401) {
        // Trigger OTP verification process
        Swal.fire("Info", "Please verify your account.", "info");
        console.log(loginData)
        const otpResponse = await axiosPublic.post("/users/verify", {
          reference: loginData.login, 
        });

        setView("verify-otp-signup");
       // setView("verify-otp-signup");
      } else {
        console.error("Login failed:", error);
        Swal.fire("Error", error.response?.data?.message || "Login failed. Please check your credentials and try again.", "error");
      }
    }
  };

  return (
    <div className="p-8 max-w-md w-full bg-[#007055]">
      <h2 className="text-3xl md:text-[40px] font-bold py-4 sm:py-8">Login</h2>

      <form onSubmit={handleLogin}>
        <div className="mb-4">
          <label className="block text-[16px] mb-2">
            Username, Email or Phone
          </label>
          <input
            type="text"
            name="login"
            value={loginData.login}
            onChange={handleChange}
            placeholder="Enter your login"
            className="w-full border-b border-[#ffffff] bg-inherit text-white py-1"
          />
        </div>
        <div className="mb-6">
          <label className="block text-[16px] mb-2">Password</label>
          <input
            type="password"
            name="password"
            value={loginData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            className="w-full border-b border-[#ffffff] bg-inherit text-white py-1"
          />
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-gray-100 rounded text-black hover:bg-gray-500 hover:text-white duration-200"
        >
          Log In
        </button>
        <p className="mt-4 text-center font-light">
          Don't have an account{" "}
          <button
            type="button"
            onClick={() => setView("signup")}
            className="text-blue-500 sm:text-white hover:text-blue-500 font-bold duration-300"
          >
            Sign Up
          </button>
        </p>
        <p className="text-center font-light">
          Forgot password{" "}
          <button
            type="button"
            onClick={() => setView("forgot")}
            className="text-blue-500 sm:text-white hover:text-blue-500 font-bold duration-300"
          >
            Reset
          </button>
        </p>
      </form>
    </div>
  );
}
