import React, { useState, useEffect } from "react";
import DoctorFilter from "../../components/patient/DoctorFilter";
import DoctorCard from "../../components/patient/doctorCard";
import PatientLayout from "../../layouts/patientLayout";
import useAxios from "../../hooks/useAxios";
import Pagination from "react-js-pagination";

export default function FindConsultant() {
  // Initiate pagination states
  const [recordCount, setRecordCount] = useState(0);
  const [totalPage, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataLimit, setDataLimit] = useState(16);

  const [doctors, setDoctors] = useState([]);

  // Initiates filter states
  const [filterName, setFilterName] = useState("");
  const [filterSpecialization, setFilterSpecialization] = useState("");
  const [filterQualification, setFilterQualification] = useState("");

  const { axiosUser } = useAxios();

  useEffect(() => {
    fetchDoctors(1, dataLimit);
  }, [axiosUser]);

  const fetchDoctors = async function (
    pageNum = 1,
    limit = 16,
    name = "",
    specializations = "",
    qualifications = ""
  ) {
    try {
      // Make the API call
      const response = await axiosUser.get(
        `/doctors?page=${pageNum}&limit=${limit}&specializations=${specializations}&qualifications=${qualifications}&name=${name}`
      );

      // Extract data from the response
      const { count, pages, page, data: doctorData } = response.data;

      // Set states
      setRecordCount(count);
      setTotalPages(pages);
      setCurrentPage(page);
      setDoctors(doctorData);
    } catch (error) {
      console.error("Error fetching doctors", error);
    }
  };

  const handlePageChange = async (pageNumber) => {
    setCurrentPage(pageNumber);
    await fetchDoctors(pageNumber, dataLimit, filterName, filterSpecialization, filterQualification);
  };

  const handleDataLimitChange = async (event) => {
    const limit = Number(event.target.value);
    setDataLimit(limit);
    await fetchDoctors(currentPage, limit, filterName, filterSpecialization, filterQualification);
  };

  const handleFilter = async () => {
    await fetchDoctors(currentPage, dataLimit, filterName, filterSpecialization, filterQualification);
  };

  return (
    <PatientLayout>
      <div className="p-4 sm:p-8 min-h-[80vh] flex flex-col">
        <DoctorFilter
          setFilterName={setFilterName}
          setFilterSpecialization={setFilterSpecialization}
          setFilterQualification={setFilterQualification}
          handleFilter={handleFilter}
        />
        <div className="flex items-center text-md">
          <div className="font-medium">Per Page</div>
          <select
            value={dataLimit}
            onChange={handleDataLimitChange}
            className="p-2 rounded-md bg-[#F6F6F6]"
          >
            <option value={8}>8</option>
            <option value={16}>16</option>
            <option value={32}>32</option>
            <option value={64}>64</option>
          </select>
        </div>
        {(Number(currentPage) !== 0 && Number(recordCount) === 0) && (
          <div className="flex-grow flex justify-center items-center text-gray-500">
            No Doctor records were found
          </div>
        )}
        <div className="flex-grow">
          <div className="flex justify-center flex-wrap">
            {doctors.map((doctor) => (
              <DoctorCard key={doctor._id} doctor={doctor}/>
            ))}
          </div>
          {(Number(recordCount) !== 0 && Number(recordCount) > dataLimit) && (
            <Pagination
              activePage={Number(currentPage)}
              itemsCountPerPage={Number(dataLimit)}
              totalItemsCount={Number(recordCount)}
              onChange={handlePageChange}
              innerClass="flex justify-center space-x-1 mt-4"
              itemClass="px-3 py-1 border rounded"
              activeClass="bg-[#7AAFA6]"
              activeLinkClass="text-white"
              linkClass="text-black hover:text-[#007055]"
              disabledClass="opacity-50 cursor-not-allowed"
              hideNavigation={true}
            />
          )}
        </div>
      </div>
    </PatientLayout>
  );
}
