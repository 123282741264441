import React, {Fragment, useEffect, useState} from 'react';
import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers, useRTCClient, useClientEvent
} from "agora-rtc-react";
import {toast} from 'react-toastify';
import {useLocation, useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Draggable from "react-draggable";

function VideoCall() {

  // Initialize component states
  const [calling, setCalling] = useState(false);
  const [micOn, setMic] = useState(false);
  const [cameraOn, setCamera] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  const {localMicrophoneTrack} = useLocalMicrophoneTrack(micOn);
  const {localCameraTrack} = useLocalCameraTrack(cameraOn);

  // Call necessary hooks
  const location = useLocation();
  const navigate = useNavigate();
  const {userData} = useAuth();

  // Extract component props from location state
  const {appointment, appId, channel, uid, token} = location.state || {};
  const {patient, doctor} = appointment;

  // Join RTC room & Publish Camera & Microphone Tracks
  useJoin({appid: appId, channel: channel, token: token ? token : null, uid}, calling);
  usePublish([localMicrophoneTrack, localCameraTrack]);

  const isConnected = useIsConnected();
  const remoteUsers = useRemoteUsers();
  const client = useRTCClient();

  const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    theme: "colored",
  };

  const countRemoteUsers = Number(remoteUsers.length);
  const remoteUser = remoteUsers[0];

  // Event handler for user joined
  useClientEvent(client, "user-joined", (event) => {

    const joinedUserUID = event.uid;

    (joinedUserUID === doctor.id && userData.role !== 'doctor') &&
    toast.success(`Doctor ${doctor.name} has joined the call`, toastOptions);

    (joinedUserUID === patient.id && userData.role !== 'patient') &&
    toast.success(`Patient ${patient.name} has joined the call`, toastOptions);
  });

  // Event handler for user left
  useClientEvent(client, "user-left", (event) => {

    const leftUserUID = event.uid;

    (leftUserUID === doctor.id && userData.role !== 'doctor') &&
    toast.warn(`Doctor ${doctor.name} has left the call`, toastOptions);

    (leftUserUID === patient.id && userData.role !== 'patient') &&
    toast.warn(`Patient ${patient.name} has left the call`, toastOptions);
  });

  client.on("error", (error) => {
    console.log('Error', error);
  });

  client.on("token-privilege-did-expire", async function () {
    setCalling(false);
    setMic(false);
    setCamera(false);
    setTokenExpired(true);
  });

  const toggleAudio = () => {
    setMic((a) => !a);
    const message = micOn ? 'Device audio has been muted' : 'Device audio has been unmuted';
    micOn ? toast.warn(message, toastOptions) : toast.success(message, toastOptions);
  }

  const toggleVideo = () => {
    setCamera((a) => !a);
    const message = cameraOn ? 'Device video has been disabled' : 'Device video has been enabled';
    cameraOn ? toast.warn(message, toastOptions) : toast.success(message, toastOptions);
  }

  const toggleCall = () => {
    setCalling((a) => !a);
    const message = calling ? 'Call has been ended' : 'Call has been started';
    if (calling) {
      setMic(false);
      setCamera(false);
      toast.warn(message, toastOptions);
    } else {
      setMic(true);
      setCamera(true);
      toast.success(message, toastOptions);
    }
  }

  if (tokenExpired) {
    toast.error("Video call time limit exceeded! Please join again.", toastOptions);
    navigate('/' + userData.role + '/appointment');
  }

  return (
    <Fragment>
      {(countRemoteUsers > 0 && isConnected) && (
        <Draggable>
          <div
            className="z-[500] fixed top-5 left-5 w-[200px] h-[160px] md:w-[225px] md:h-[180px] lg:w-[250px] lg:h-[200px]">
            <LocalUser
              audioTrack={localMicrophoneTrack}
              cameraOn={cameraOn}
              micOn={micOn}
              videoTrack={localCameraTrack}
              playAudio={false}
              cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
            />
          </div>
        </Draggable>
      )}
      <div className="flex flex-col h-screen">
        <div className="flex-1">
          {isConnected ? (
            countRemoteUsers > 0 ? (
              <RemoteUser
                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                user={remoteUser}
              />
            ) : (
              <LocalUser
                audioTrack={localMicrophoneTrack}
                cameraOn={cameraOn}
                micOn={micOn}
                videoTrack={localCameraTrack}
                playAudio={false}
                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
              />
            )
          ) : (
            <div className="h-[100%] flex flex-col items-center justify-center bg-[#374151] gap-3">
              <div className="">
                <p className="text-2xl text-white m-2">Appointment Video Call</p>
              </div>
              <div className="flex items-center justify-center gap-3">
                <button
                  className="bg-[#D3E4E1] text-black hover:bg-[#007055] hover:text-white py-2 px-4 rounded-lg font-semibold"
                  onClick={toggleCall}>
                  Join Call
                </button>
                <button className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-lg font-semibold"
                        onClick={() => navigate(-1)}>
                  Cancel Call
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-center items-center h-[70px] gap-3 bg-[#008A73]">

          <button
            className="bg-gray-700 text-white w-12 h-12 flex items-center justify-center rounded-full transition duration-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={toggleAudio} title={micOn ? "Mute Audio" : "Unmute Audio"}
            disabled={!calling}
          >
            <i className={`fas fa-microphone ${!micOn ? "text-red-500" : ""}`}/>
          </button>

          <button
            className="bg-gray-700 text-white w-12 h-12 flex items-center justify-center rounded-full transition duration-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={toggleVideo} title={cameraOn ? "Disable Video" : "Enable Video"}
            disabled={!calling}
          >
            <i className={`fas fa-video ${!cameraOn ? "text-red-500" : ""}`}/>
          </button>

          <button
            className="bg-gray-700 text-white w-12 h-12 flex items-center justify-center rounded-full transition duration-300 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={toggleCall} title={calling ? "End Video Call" : "Join Video Call"}
          >
            <i className={`fas fa-phone ${!calling ? "text-red-500" : ""}`}/>
          </button>

        </div>
      </div>
    </Fragment>
  );
}

export default VideoCall;
