import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuth from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxios";

const ProtectRoute = ({ allowedRoles }) => {

  const { loading, isLoggedIn, userData } = useAuth();
  const location = useLocation();
  const { axiosUser } = useAxios();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingProfileError, setLoadingProfileError] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(null);

  const { message = 'something', applied = false } = location.state || {};

  useEffect(() => {
    const fetchProfile = async () => {
      setLoadingProfile(true);
      try {
        const response = await axiosUser.get("/doctors/profile");
        setReviewStatus(response.data.review_status);
      } catch (error) {
        setLoadingProfileError(error);
        console.log(error)
      }
    };
    if (userData && userData?.role === 'doctor') {
      fetchProfile();
      setLoadingProfile(false);
    }
  }, [axiosUser, userData]);

  if (loading || loadingProfile) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  if (!allowedRoles.includes(userData?.role)) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  if (userData?.role === 'doctor') {
    if (reviewStatus === "pending") {
      return <Navigate to="/doctor/verification" state={{ from: location, message: "Your profile is currently being review.", applied: true }} replace />;
    }

    if (reviewStatus === "rejected") {
      return <Navigate to="/doctor/verification" state={{ from: location, message: "Your profile was rejected. Please contact admin.", applied: true }} replace />;
    }

    if (loadingProfileError) {
      return <Navigate to="/doctor/verification" state={{ from: location, applied: false }} replace />;
    }
  }

  return <Outlet />;
};

export default ProtectRoute;
