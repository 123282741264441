import React, { useState } from "react";
import useAxios from "../hooks/useAxios";
import Signup from "./Auth/Signup";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/ForgotPassword";
import VerifyOTP from "./Auth/VerifyOTP";
import ResetPassword from "./Auth/ResetPassword";

export default function Auth() {
  const [view, setView] = useState("login");
  const { axiosPublic } = useAxios();
  const [verificationCode, setVerificationCode] = useState("");
  const [passwordResetToken, setPasswordResetToken] = useState("");
  const [role, setRole] = useState("");


  return (
    <div className="min-h-screen bg-[#D3E4E1] text-white">
      <div
        className={`flex flex-col items-center pt-8 mb-4 $`}
      >
        <h2 className="text-5xl sm:text-[70px] text-[#7AAFA6]">
          Welcome to <br />
        </h2>
        <h2 className="text-7xl sm:text-[106px] text-[#008A73] font-bold">
          Nimbus
        </h2>
      </div>

      <div className="flex items-center justify-center h-full">
        {view === "login" && (
          <Login setView={setView} axiosPublic={axiosPublic} useCase="veriySignup"/>
        )}

        {view === "signup" && (
          <Signup setView={setView} axiosPublic={axiosPublic} setRole={setRole}/>
        )}

        {view === "forgot" && (
          <ForgotPassword
            setView={setView}
            axiosPublic={axiosPublic}
            setVerificationCode={setVerificationCode}
          />
        )}

        {view === "verify-otp-reset" && (
          <VerifyOTP
            setView={setView}
            axiosPublic={axiosPublic}
            setVerificationCode={setVerificationCode}
            setPasswordResetToken={setPasswordResetToken}
            useCase="resetPassword"
          />
        )}

        {view === "verify-otp-signup" && (
          <VerifyOTP
            setView={setView}
            axiosPublic={axiosPublic}
            useCase="verifySignup"
            
          />
        )}

        {view === "resetPassword" && (
          <ResetPassword
            setView={setView}
            axiosPublic={axiosPublic}
            passwordResetToken={passwordResetToken}
          />
        )}
      </div>
    </div>
  );
}
