import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";

export default function DoctorFilter({ setFilterName, setFilterSpecialization, setFilterQualification, handleFilter }) {
  const [name, setName] = useState("");
  const [specializations, setSpecializations] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  const [selectedQualification, setSelectedQualification] = useState("");
  const { axiosUser } = useAxios();

  useEffect(() => {
    const fetchQualifications = async () => {
      try {
        const response = await axiosUser.get("/qualifications/all");
        setQualifications(response.data);
      } catch (error) {
        console.error("Failed to fetch qualification data", error);
      }
    };

    const fetchSpecializations = async () => {
      try {
        const response = await axiosUser.get("/specializations/all");
        setSpecializations(response.data);
      } catch (error) {
        console.error("Failed to fetch specialization data", error);
      }
    };

    fetchQualifications();
    fetchSpecializations();
  }, [axiosUser]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleFilter();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [name, selectedSpecialization, selectedQualification]);

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setFilterName(value);
  };

  const handleSpecializationChange = (e) => {
    const value = e.target.value;
    setSelectedSpecialization(value);
    setFilterSpecialization(value);
  };

  const handleQualificationChange = (e) => {
    const value = e.target.value;
    setSelectedQualification(value);
    setFilterQualification(value);
  };

  return (
    <div className="py-6 bg-[#A6CAC4] px-2 sm:px-4 rounded-lg mb-4">
      <form className="max-w-4xl mx-auto pt-4">
        <div className="flex flex-col sm:flex-row justify-between sm:items-end gap-4">
          <div className="flex-1">
            <label className="font-medium block mb-2">Name</label>
            <input
              type="text"
              id="name"
              placeholder="John Doe"
              value={name}
              onChange={handleNameChange}
              className="w-full p-2 rounded-md bg-[#F6F6F6]"
            />
          </div>
          <div className="flex-1 mt-2">
            <label className="font-medium block mb-2">Specialization</label>
            <select
              id="specialization"
              value={selectedSpecialization}
              onChange={handleSpecializationChange}
              className="w-full p-2 rounded-md bg-[#F6F6F6]"
            >
              <option value="">All</option>
              {specializations.length > 0 && specializations.map((spec) => (
                <option key={spec._id} value={spec._id}>{spec.name}</option>
              ))}
            </select>
          </div>
          <div className="flex-1">
            <label className="font-medium block mb-2">Qualifications</label>
            <select
              id="qualifications"
              value={selectedQualification}
              onChange={handleQualificationChange}
              className="w-full p-2 bg-[#F6F6F6] rounded-md"
            >
              <option value="">All</option>
              {qualifications.length > 0 && qualifications.map((qualification) => (
                <option key={qualification._id} value={qualification._id}>{qualification.name}</option>
              ))}
            </select>
          </div>
        </div>
      </form>
    </div>
  );
}
