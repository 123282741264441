import React, { useState } from "react";

export default function ResetPassword({ setView, axiosPublic, passwordResetToken }) {
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordData.password !== passwordData.confirmPassword) {
      setError("Passwords do not match. Please try again.");
      return;
    }



    try {
      const response = await axiosPublic.put(`/auth/reset-password/`, {
        password: passwordData.password,
        confirmPassword: passwordData.confirmPassword,
      }, {
        headers: {
            'Authorization': `Bearer ${passwordResetToken}`
        }
      });
      setMessage(response.data.message);
      setError("");

      // Redirect to login page after successful password reset
      setView("login");
    } catch (error) {
      setError(error.response?.data?.message || "Failed to reset password. Please try again.");
      setMessage("");
    }
  };

  return (
    <div className="p-8 max-w-md w-full space-y-8">
      <h2 className="mb-6 text-3xl font-bold">Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block">New Password</label>
          <input
            type="password"
            name="password"
            value={passwordData.password}
            onChange={handleChange}
            placeholder="New Password"
            className="w-full py-1 bg-inherit border-b border-white text-white"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block">Confirm New Password</label>
          <input
            type="password"
            name="confirmPassword"
            value={passwordData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm New Password"
            className="w-full py-1 bg-inherit border-b border-white text-white"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-gray-100 rounded text-black hover:bg-gray-500 hover:text-white duration-200"
        >
          Reset Password
        </button>
        {message && <p className="mt-4 text-green-500">{message}</p>}
        {error && <p className="mt-4 text-red-500">{error}</p>}
      </form>
    </div>
  );
}
