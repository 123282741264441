import React, { useEffect, useState, useMemo } from "react";
import useAxios from "../../hooks/useAxios";
import useAuth from "../../hooks/useAuth";

export default function UpdateInfoForm() {
  const { axiosUser } = useAxios();
  const { userData, tokenData, setUserData } = useAuth();

  // Memoize initialFormData to prevent infinite loop
  const initialFormData = useMemo(() => {
    const data = { ...userData };
    delete data._id;
    delete data.role;
    delete data.verified;
    delete data.updatedAt;
    delete data.createdAt;
    return data;
  }, [userData]);

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    const updatedFormData = { ...initialFormData };
    if (updatedFormData.date_of_birth) {
      updatedFormData.date_of_birth = updatedFormData.date_of_birth.split("T")[0];
    }
    setFormData(updatedFormData);
  }, [initialFormData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const profileUpdate = await axiosUser.put(`users/${userData._id}`, formData);
      //TODO: update user data
      // setUserData(profileUpdate)
      alert("Profile updated successfully!");
    } catch (error) {
      // Todo: Add toast notification
      console.error(error);
      alert(error.response?.data?.message);
    }
  };

  const formatLabel = (label) => {
    let formattedInfo = label.replace(/_/g, " ");
    formattedInfo = formattedInfo.replace(/\b\w/g, (char) => char.toUpperCase());
    return formattedInfo;
  };

  return (
      <form
          onSubmit={handleSubmit}
          className="max-w-3xl mx-auto bg-white px-0 sm:px-4 py-8 rounded-lg"
      >
        {Object.entries(formData).map(([key, value]) => (
            <div key={key} className="mb-4 flex items-center">
              <label
                  htmlFor={key}
                  className="block text-sm sm:text-[20px] font-bold text-[#141921] w-1/3"
              >
                {formatLabel(key)}
              </label>
              {key === "gender" ? (
                  <select
                      id={key}
                      name={key}
                      value={value}
                      onChange={handleChange}
                      className="mt-1 text-sm sm:text-[20px] block w-2/3 px-3 py-2 bg-[#F9F9F9] border-gray-300 sm:text-sm rounded-lg"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
              ) : key === "date_of_birth" ? (
                  <input
                      type="date"
                      id={key}
                      name={key}
                      value={value}
                      onChange={handleChange}
                      className="mt-1 text-sm sm:text-[20px] block w-2/3 px-3 py-2 bg-[#F9F9F9] border-gray-300 sm:text-sm rounded-lg"
                  />
              ) : (
                  <input
                      type={key === "email" ? "email" : "text"}
                      id={key}
                      name={key}
                      value={value}
                      onChange={handleChange}
                      className="mt-1 text-sm sm:text-[20px] block w-2/3 px-3 py-2 bg-[#F9F9F9] border-gray-300 sm:text-sm rounded-lg"
                  />
              )}
            </div>
        ))}

        <div className="py-4"></div>

        <div className="py-6 border-t border-[#B3B3B3] flex justify-end">
          <button
              type="submit"
              className="py-2 px-4 rounded-lg shadow-sm text-sm sm:text-[18px] font-medium text-white bg-[#007055] hover:bg-green-500 duration-300"
          >
            Save Changes
          </button>
        </div>
      </form>
  );
}
