import React, { useState } from 'react';
import useAxios from "../hooks/useAxios";

const SendMessageModal = ({ doctor, isOpen, onClose }) => {

  const [message, setMessage] = useState('');
  const { axiosUser } = useAxios();

  const handleSendMessage = async () => {
    const {user_id} = doctor;
    try {
      const response = await axiosUser.post(`/chats/${user_id._id || user_id.id}`, {
        message: message
      });
      alert('Message sent successfully');
      onClose();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
        <div className="bg-white rounded-lg shadow-lg max-w-md w-full mx-4">
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-bold">Send Message to Dr. {doctor.user_id.name}</h2>
            <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="p-4">
            <textarea
              className="w-full p-2 border border-gray-300 rounded-md"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
            />
          </div>
          <div className="flex justify-end p-4 border-t">
            <button
              onClick={onClose}
              className="bg-black text-white px-4 py-2 rounded-lg mr-2 hover:bg-red-600">
              Close
            </button>
            <button
              onClick={handleSendMessage}
              className="bg-[#007055] hover:bg-[#008A73] text-white px-4 py-2 rounded-lg">
              Send Message
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMessageModal;
