import React, { useState, useEffect } from "react";
import { AiOutlineSchedule } from "react-icons/ai";
import TitleDoctor from "../../components/doctor/global/title";
import DoctorLayout from "../../layouts/doctorLayout";
import useAxios from "../../hooks/useAxios"; // Replace with the actual path to your useAxios hook

export default function Dashboard() {
  const [statusCounts, setStatusCounts] = useState({
    pending: 0,
    completed: 0,
    cancelled: 0,
    confirmed: 0,
    rescheduled: 0,
  });
  const { axiosUser } = useAxios(); // Assuming useAxios provides an axios instance
  const [totalAppointments, setTotalAppointments] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosUser.get("/statistics");
        const data = response.data;
        setStatusCounts(data.statusCounts);

        // Calculate total appointments
        const total = Object.values(data.statusCounts).reduce(
            (acc, count) => acc + count,
            0
        );
        setTotalAppointments(total);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [axiosUser]);

  return (
      <DoctorLayout>
        <div className="p-8 h-screen">
          <TitleDoctor title={"Dashboard"} />

          {/* Section for total appointments */}
          <section className="bg-[#007055] sm:w-full p-8 rounded-lg text-white">
            <p className="text-4xl">
              <AiOutlineSchedule />
            </p>

            <p className="text-6xl py-4">{totalAppointments}</p>
            <p className="text-2xl font-light">Total Appointments</p>
          </section>

          {/* Sections for individual status counts */}
          <div className="flex flex-col sm:flex-row w-full py-8 gap-4">
            <section className="bg-[#007055] sm:w-1/5 p-8 rounded-lg text-white">
              <p className="text-4xl">
                <AiOutlineSchedule />
              </p>

              <p className="text-6xl py-4">{statusCounts.pending}</p>
              <p className="text-2xl font-light">Pending</p>
            </section>

            <section className="bg-[#D3E4E1] sm:w-1/5 p-8 rounded-lg text-[#007055]">
              <p className="text-4xl">
                <AiOutlineSchedule />
              </p>

              <p className="text-6xl py-4">{statusCounts.completed}</p>
              <p className="text-2xl font-light">Completed</p>
            </section>

            <section className="bg-[#FCD34D] sm:w-1/5 p-8 rounded-lg text-[#007055]">
              <p className="text-4xl">
                <AiOutlineSchedule />
              </p>

              <p className="text-6xl py-4">{statusCounts.cancelled}</p>
              <p className="text-2xl font-light">Cancelled</p>
            </section>

            <section className="bg-[#9AE6B4] sm:w-1/5 p-8 rounded-lg text-[#007055]">
              <p className="text-4xl">
                <AiOutlineSchedule />
              </p>

              <p className="text-6xl py-4">{statusCounts.confirmed}</p>
              <p className="text-2xl font-light">Confirmed</p>
            </section>

            <section className="bg-[#E3D8FF] sm:w-1/5 p-8 rounded-lg text-[#007055]">
              <p className="text-4xl">
                <AiOutlineSchedule />
              </p>

              <p className="text-6xl py-4">{statusCounts.rescheduled}</p>
              <p className="text-2xl font-light">Rescheduled</p>
            </section>
          </div>
        </div>
      </DoctorLayout>
  );
}
