import React, { useState } from "react";
import useAxios from "../../hooks/useAxios";
import useAuth from "../../hooks/useAuth";

export default function UpdatePasswordForm() {
  const { axiosUser } = useAxios();
  const { userData, tokenData, setUserData } = useAuth();

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try{

      const passwordUpdate = await axiosUser.put(`auth/change-password`, formData);

      alert('Password updated successfully')
      console.log("Password Updated:", formData.newPassword);
      // Implement password update logic here

    } catch (error) {
      // Todo: Add toast notification
      console.log(error.response);
      alert(error.response?.data?.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-8">
      <div className="mb-6">
        <label
          htmlFor="oldPassword"
          className="block text-sm font-bold sm:text-[20px]  text-[#141921] pb-2"
        >
          Current Password
        </label>
        <input
          type="password"
          id="oldPassword"
          name="oldPassword"
          value={formData.oldPassword}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 bg-[#F9F9F9] sm:text-sm rounded-lg"
        />
      </div>
      <div className="mb-6">
        <label
          htmlFor="newPassword"
          className="block text-sm font-bold sm:text-[20px]  text-[#141921] pb-2"
        >
          New Password
        </label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          value={formData.newPassword}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 bg-[#F9F9F9] sm:text-sm rounded-lg"
        />
      </div>
      <div className="mb-6">
        <label
          htmlFor="confirmPassword"
          className="block text-sm font-bold sm:text-[20px]  text-[#141921] pb-2"
        >
          Confirm New Password
        </label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          className="mt-1 block w-full px-3 py-2 bg-[#F9F9F9] sm:text-sm rounded-lg"
        />
      </div>

      <div className="pt-4 flex justify-end">
        <button
          type="submit"
          className="py-2 px-4 rounded-lg shadow-sm text-sm font-medium text-white bg-[#007055] hover:bg-green-500 duration-300"
        >
          Update Password
        </button>
      </div>
    </form>
  );
}
