import React, {useState, useEffect} from "react";
import useAuth from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxios";
import AppointmentRescheduleModal from "../AppointmentRescheduleModal";
import {useNavigate} from 'react-router-dom';
import useApp from "../../hooks/useApp";

const AppointmentTable = ({appointments, selectedStatus, updateStatus, onReschedule, loadItems}) => {
  const {userData} = useAuth();
  const {setVideoCallActive} = useApp();
  const [showAction, setShowAction] = useState(false);
  const {axiosUser} = useAxios();
  const [isAppointmentRescheduleModalOpen, setIsAppointmentRescheduleModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setShowAction(['pending', 'rescheduled', 'all'].includes(selectedStatus));
  }, [selectedStatus]);

  const handleRateSubmit = async (appointmentId, rating) => {
    try {
      await axiosUser.put(`/appointments/${appointmentId}/rating`, {
        rating: rating,
      });
      alert('Appointment rating added successfully');
      loadItems();
    } catch (error) {
      console.error('Error rating', error);
    }
  };

  const openRescheduleAppointmentModal = (appointment) => {
    setSelectedAppointment(appointment);
    setIsAppointmentRescheduleModalOpen(true);
  };

  const closeModals = () => {
    setIsAppointmentRescheduleModalOpen(false);
    setSelectedAppointment(null);
    loadItems();
  };

  const renderAction = (appointment) => {
    const actions = [];
    const currentDateTime = new Date();
    const appointmentDateTime = new Date(appointment.date_time);

    if ((userData.role === 'doctor' && (appointment.status === 'pending' || (appointment.status === 'rescheduled' && appointment.reschedules.length > 0 && appointment.reschedules[appointment.reschedules.length - 1].rescheduled_by === 'patient'))) ||
      (userData.role === 'patient' && appointment.status === 'rescheduled' && appointment.reschedules.length > 0 && appointment.reschedules[appointment.reschedules.length - 1].rescheduled_by === 'doctor')) {
      actions.push(
        <button
          key="confirm"
          className="bg-green-500 text-white py-1 px-3 rounded-lg mr-2"
          onClick={() => updateStatus(appointment._id, 'confirmed')}
        >
          Confirm
        </button>
      );
    }

    if (appointment.status === 'pending' ||
      (userData.role === 'patient' && appointment.status === 'rescheduled' && appointment.reschedules.length > 0 && appointment.reschedules[appointment.reschedules.length - 1].rescheduled_by === 'doctor') ||
      (userData.role === 'doctor' && appointment.status === 'rescheduled' && appointment.reschedules.length > 0 && appointment.reschedules[appointment.reschedules.length - 1].rescheduled_by === 'patient')) {
      actions.push(
        <button
          key="cancel"
          className="bg-red-500 text-white py-1 px-3 rounded-lg mr-2"
          onClick={() => updateStatus(appointment._id, 'cancelled')}
        >
          Cancel
        </button>
      );
    }

    if ((appointment.status === 'rescheduled' || appointment.status === 'pending') &&
      ((userData.role === 'doctor' && (appointment.reschedules.length === 0 || (appointment.reschedules.length > 0 && appointment.reschedules[appointment.reschedules.length - 1].rescheduled_by === 'patient'))) ||
        (userData.role === 'patient' && (appointment.reschedules.length > 0 && appointment.reschedules[appointment.reschedules.length - 1].rescheduled_by === 'doctor')))) {
      actions.push(
        <button
          key="reschedule"
          onClick={() => openRescheduleAppointmentModal(appointment)}
          className="bg-[#007055] text-white rounded-lg px-2 py-2 focus:outline-none hover:bg-[#008A73]"
        >
          Reschedule
        </button>
      );
    }

    if (appointment.status === 'confirmed' && appointmentDateTime <= currentDateTime) {
      actions.push(
        <button
          key="join"
          onClick={() => joinVideoCall(appointment)}
          className="bg-blue-500 text-white rounded-lg px-2 py-2 focus:outline-none hover:bg-blue-600"
        >
          Join Call
        </button>
      )

      if(userData.role === 'doctor'){
        actions.push(
            <button
                key="complete"
                className="bg-green-500 text-white py-1 px-3 rounded-lg mr-2"
                onClick={() => updateStatus(appointment._id, 'completed')}
            >
              Complete
            </button>
        )
      }

    }

    if (actions.length === 0) {
      return <span>No action available</span>;
    }

    return actions;
  };

  const joinVideoCall = async (appointment) => {
    try {

      const response = await axiosUser.get(`/appointments/${appointment._id}/video-call-access-token`);
      const {app_id, token} = response.data;

      navigate('/video-call', {
        state: {
          appointment,
          appId: app_id,
          channel: appointment._id,
          uid: userData._id,
          token: token,
        }
      });

    } catch (error) {
      console.error("Failed to fetch video call access token", error);
    }
  };

  return (
    <div className="overflow-x-auto">
      {appointments.length > 0 ? (
        <table className="min-w-full bg-white">
          <thead>
          <tr>
            <th className="py-2 px-4 border-b-2 text-center">
              {userData.role === "doctor" ? "Patient" : "Doctor"}
            </th>
            <th className="py-2 px-4 border-b-2 text-center">Date</th>
            <th className="py-2 px-4 border-b-2 text-center">Time</th>
            {selectedStatus === 'all' && (
              <th className="py-2 px-4 border-b-2 text-center">Status</th>
            )}
            {(selectedStatus === 'all' || selectedStatus === 'completed') && (
              <th className="py-2 px-4 border-b-2 text-center">Rating</th>
            )}
            {showAction && <th className="py-2 px-4 border-b-2 text-center">Action</th>}
          </tr>
          </thead>
          <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment._id}>
              <td className="py-2 px-4 border-b text-center">
                {userData.role === "doctor" ? appointment.patient?.name : appointment.doctor?.name}
              </td>
              <td className="py-2 px-4 border-b text-center">
                {new Date(appointment.date_time).toLocaleDateString()}
              </td>
              <td className="py-2 px-4 border-b text-center">
                {new Date(appointment.date_time).toLocaleTimeString()}
              </td>
              {selectedStatus === 'all' && (
                <td className="py-2 px-4 border-b text-center">{appointment.status}</td>
              )}
              {selectedStatus === 'all' || selectedStatus === 'completed' ? (
                <td>
                  {appointment.status === 'completed' ? (
                    appointment.rating && appointment.rating > 0 ? (
                      <div className="flex items-center justify-center w-full pt-4">
                        {[...Array(5)].map((_, i) => (
                          <svg
                            key={i}
                            className={`w-4 h-4 ${i < appointment.rating ? "text-[#008A73]" : "text-gray-300"}`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M10 15l-5.878 3.09 1.122-6.545L.488 6.41l6.564-.955L10 0l2.948 5.455 6.564.955-4.756 4.135 1.122 6.545z"/>
                          </svg>
                        ))}
                      </div>
                    ) : (
                      userData.role === 'patient' ? (
                        <div>
                          <input
                            type="number"
                            min="1"
                            max="5"
                            className="w-12 pl-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          />
                          <button
                            className="bg-orange-500 text-white py-1 px-3 rounded-lg"
                            onClick={(event) => {
                              const rating = event.target.previousSibling.value;
                              handleRateSubmit(appointment._id, rating);
                            }}
                          >
                            Rate
                          </button>
                        </div>
                      ) : (
                        "No rating yet"
                      )
                    )
                  ) : (
                    "Not completed yet"
                  )}
                </td>
              ) : null}
              {showAction && (
                <td className="py-2 px-4 border-b text-center">
                  {renderAction(appointment)}
                </td>
              )}
            </tr>
          ))}
          </tbody>
        </table>
      ) : (
        <span className="flex justify-center items-center">No appointment data available</span>
      )}

      <AppointmentRescheduleModal
        appointment={selectedAppointment}
        isOpen={isAppointmentRescheduleModalOpen}
        onClose={closeModals}
      />
    </div>
  );
};

export default AppointmentTable;
