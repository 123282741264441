import React, { useEffect, useRef, useState } from "react";
import PatientLayout from "../../layouts/patientLayout";
import useApp from "../../hooks/useApp";
import useAxios from "../../hooks/useAxios";
import ReactMarkdown from 'react-markdown';
import DoctorSuggestions from "../../components/patient/DoctorSuggestions";

export default function AiChat() {

  const [inputText, setInputText] = useState("");
  const [threadMessages, setThreadMessages] = useState([]);
  const [isThinking, setIsThinking] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalMessagePages, setTotalMessagePages] = useState(1);
  const [isMessagesFetching, setIsMessagesFetching] = useState(false);
  const [initialScrolled, setInitialScrolled] = useState(false);

  const { threadId, setThreadId } = useApp();
  const { axiosUser } = useAxios();
  const messagesContainerRef = useRef(null);

  // Fetch thread id if not available
  useEffect(() => {
    const fetchThreadId = async () => {
      try {
        const response = await axiosUser.get(
          "/ai/threads/user"
        );
        setThreadId(response.data.id);
      } catch (error) {
        console.error("Failed to fetch chat rooms", error);
      }
    };

    if (!threadId) {
      fetchThreadId();
    }
  }, []);

  // Fetch AI Chat Messages
  useEffect(() => {
    const fetchThreadMessages = async () => {
      try {

        const response = await axiosUser.get(
          `/ai/threads/${threadId}/messages?page=1&limit=15&sortBy=createdAt:desc`
        );

        const messagesData = response?.data?.data;
        setThreadMessages(messagesData.reverse());
        setCurrentPage(response?.data?.page);
        setTotalMessagePages(response?.data?.pages);

      } catch (error) {
        console.error("Failed to fetch chat rooms", error);
      }
    };

    if (threadId) {
      fetchThreadMessages();
    }
  }, [threadId]);

  // Scroll the message container initially
  useEffect(() => {
    if(!initialScrolled && Number(currentPage) === 1){
      scrollMessageContainer();
      setInitialScrolled(true);
    }
  }, [threadMessages]);


  // Handle scroll to fetch previous messages
  useEffect(() => {
    const handleScroll = async () => {

      if (messagesContainerRef.current.scrollTop === 0 &&
        !isMessagesFetching && currentPage < totalMessagePages) {

        setIsMessagesFetching(true);

        try {

          const response = await axiosUser.get(
            `/ai/threads/${threadId}/messages?page=${Number(currentPage) + 1}&limit=15&sortBy=createdAt:desc`
          );
          const newMessages = response?.data?.data?.reverse();

          setThreadMessages((prevMessages) => [...newMessages, ...prevMessages]);

          setCurrentPage((prevPage) => Number(prevPage) + 1);
          setTotalMessagePages(response?.data?.pages);

        } catch (error) {
          console.error("Failed to fetch previous messages", error);
        } finally {
          setIsMessagesFetching(false);
        }
      }
    };

    const messagesContainer = messagesContainerRef.current;
    messagesContainer.addEventListener("scroll", handleScroll);

    return () => {
      messagesContainer.removeEventListener("scroll", handleScroll);
    };
  }, [currentPage, isMessagesFetching, axiosUser]);


  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  // Handle user message send to the Nimbus AI
  const handleSend = async () => {
    if (!inputText.trim()) return; // Avoid sending empty messages

    // Set temporary message
    const newMessage = {
      message: inputText,
      role: 'user',
      _id: Date.now().toString(),
      status: 'sending'
    };
    setThreadMessages(prevMessages => [...prevMessages, newMessage]); // Update the state optimistically
    setIsThinking(true);

    try {
      // Send message API request
      const response = await axiosUser.post(`/ai/threads/${threadId}/messages`, {
        message: inputText
      });
      const savedMessage = response.data;

      // Replace the temporary message with the saved message
      setThreadMessages(prevMessages => prevMessages.map(msg =>
        msg._id === newMessage._id ? savedMessage : msg
      ));

      // Scroll the message container
      scrollMessageContainer();

      // Check for assistant's response
      const checkAssistantResponse = setInterval(async () => {
        try {
          const response = await axiosUser.get(`/ai/threads/${threadId}/assistant-response`);
          if (response.data.status === "completed") {
            clearInterval(checkAssistantResponse);
            setThreadMessages(prevMessages => {
              const messageExists = prevMessages.some(
                msg => msg._id === response.data.assistant_response._id
              );
              if (!messageExists) {
                return [...prevMessages, response.data.assistant_response];
              }
              return prevMessages;
            });
            setIsThinking(false);
            scrollMessageContainer();
          }
        } catch (error) {
          console.error("Failed to fetch assistant response", error);
        }
      }, 4000); // Check every 4 seconds


    } catch (error) {
      console.error("Failed to send message", error);
      setThreadMessages(prevMessages => prevMessages.filter(
        msg => msg._id !== newMessage._id
      )); // Remove the optimistic message in case of an error
      setIsThinking(false);
    }

    setInputText("");
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !isThinking) {
      handleSend();
    }
  };

  const scrollMessageContainer = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }

  return (
    <PatientLayout>
      <div className="w-full h-[90vh] py-8">
        <div className="bg-[#A6CAC4] max-w-2xl mx-auto overflow-hidden h-full flex flex-col">
          <div ref={messagesContainerRef}
               className="p-2 sm:p-8 space-y-4 flex-1 overflow-y-auto hide-scrollbar">
            {threadMessages.length < 1 ? (
              <div className="flex justify-center items-center h-full text-gray-500">
                Start chatting with Nimbus AI to get consultant suggestion
              </div>
            ) : (
              <div className="flex flex-col gap-4 justify-start text-sm sm:text-[14px] font-bold">
                {threadMessages.length > 0 && threadMessages.map((message) => (
                  <div
                    key={message._id}
                    className={`${
                      message.role === 'user'
                        ? "self-end"
                        : "self-start"
                    }`}
                  >
                    <div className={`rounded-lg p-3 ${
                      message.role === 'user'
                        ? "max-w-xs bg-[#008A73]"
                        : "w-full bg-white"
                    }`}>
                    <span
                      className={`${
                        message.role === 'user'
                          ? "text-white"
                          : "text-dark"
                      }`}
                    >
                      <ReactMarkdown>{message.message}</ReactMarkdown>
                      {(message.role === 'assistant' && message.data && message.data.length > 0) && (
                        <DoctorSuggestions data={message.data}/>
                      )}
                    </span>
                    </div>
                    {message.role === 'user' && message.status === 'sending' && <small>Sending</small>}
                  </div>
                ))}
              </div>
            )}
          </div>
          {threadId && (
            <div className="bg-[#007055] p-4">
              {isThinking && <div className="text-white mb-4">Nimbus is thinking...</div>}
              <div className="flex items-end space-x-2">
                <input
                  type="text"
                  value={inputText}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Type a message"
                  className="flex-1 p-2 border border-gray-300 rounded-lg focus:outline-none"
                  disabled={isThinking}
                />
                <button
                  onClick={handleSend}
                  className="bg-white text-black rounded-lg px-6 py-2 focus:outline-none hover:bg-[#D3E4E1]"
                  disabled={isThinking}
                >
                  Send
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </PatientLayout>
  );
}