import React, { useState } from "react";
import Swal from "sweetalert2";

export default function Signup({ setView, axiosPublic }) {
  const [signupData, setSignupData] = useState({
    name: "",
    username: "",
    email: "",
    contactNumber: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
    gender: "male", // default gender selection
  });

  const [role, setRole] = useState("patient"); // Default role is patient


  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (signupData.password !== signupData.confirmPassword) {
      Swal.fire("Error", "Passwords do not match. Please try again.", "error");
      return;
    }

    try {
      const response = await axiosPublic.post("/auth/register", {
        name: signupData.name,
        username: signupData.username,
        email: signupData.email,
        contact_number: signupData.contactNumber,
        password: signupData.password,
        date_of_birth: signupData.dateOfBirth,
        gender: signupData.gender,
        role, // Pass the selected role
      });
      const userData = response.data;


      const otpResponse = await axiosPublic.post("/users/verify", {
        reference: signupData.email || signupData.contact_number, 
      });

      Swal.fire("Success", otpResponse.data.message, "success");

      setMessage(otpResponse.data.message);
      setError("");

      // Navigate to OTP verification view
      setView("verify-otp-signup");

    } catch (error) {
      Swal.fire("Signup failed", "Please check your details and try again.", "error");
    }
  };


  
  return (
    <div className="p-8 max-w-md w-full space-y-8 bg-[#007055]">
      <h2 className="mb-6 text-3xl font-bold">Sign Up</h2>
      <form onSubmit={handleSignup}>
        <div className="mb-4">
          <label className="block">Name</label>
          <input
            type="text"
            name="name"
            value={signupData.name}
            onChange={handleChange}
            placeholder="Your Name"
            className="w-full py-1 bg-inherit border-b border-white text-white"
          />
        </div>
        <div className="mb-4">
          <label className="block">Username</label>
          <input
            type="text"
            name="username"
            value={signupData.username}
            onChange={handleChange}
            placeholder="Your Username"
            className="w-full py-1 bg-inherit border-b border-white text-white"
          />
        </div>
        <div className="mb-4">
          <label className="block">Email</label>
          <input
            type="email"
            name="email"
            value={signupData.email}
            onChange={handleChange}
            placeholder="Email Address"
            className="w-full py-1 bg-inherit border-b border-white text-white"
          />
        </div>
        <div className="mb-4">
          <label className="block">Contact Number</label>
          <input
            type="text"
            name="contactNumber"
            value={signupData.contactNumber}
            onChange={handleChange}
            placeholder="Contact Number"
            className="w-full py-1 bg-inherit border-b border-white text-white"
          />
        </div>
        <div className="mb-4">
          <label className="block">Password</label>
          <input
            type="password"
            name="password"
            value={signupData.password}
            onChange={handleChange}
            placeholder="Password"
            className="w-full py-1 bg-inherit border-b border-white text-white"
          />
        </div>
        <div className="mb-4">
          <label className="block">Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            value={signupData.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm Password"
            className="w-full py-1 bg-inherit border-b border-white text-white"
          />
        </div>
        <div className="mb-4">
          <label className="block">Date of Birth</label>
          <input
            type="date"
            name="dateOfBirth"
            value={signupData.dateOfBirth}
            onChange={handleChange}
            className="w-full py-1 bg-inherit border-b border-white text-white"
          />
        </div>
        <div className="mb-6">
          <label className="block">Gender</label>
          <select
            name="gender"
            value={signupData.gender}
            onChange={handleChange}
            className="w-full py-1 bg-inherit border-b border-white text-white"
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="mb-6">
          <label className="block">Role</label>
          <div className="flex">
            <label className="mr-4">
              <input
                type="radio"
                name="role"
                value="doctor"
                checked={role === "doctor"}
                onChange={handleRoleChange}
              /> Doctor
            </label>
            <label>
              <input
                type="radio"
                name="role"
                value="patient"
                checked={role === "patient"}
                onChange={handleRoleChange}
              /> Patient
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-gray-100 rounded text-black hover:bg-gray-500 hover:text-white duration-200"
        >
          Sign Up
        </button>
        <p className="mt-4 text-center font-light">
          Already have an account{" "}
          <button
            type="button"
            onClick={() => setView("login")}
            className="text-blue-500 sm:text-white hover:text-blue-500 font-bold duration-300"
          >
            Log In
          </button>
        </p>
      </form>
    </div>
  );
}