import React from "react";
import {Routes, Route} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import ProtectRoute from "./components/utils/ProtectRoute";
import PatientDashboard from "./pages/patient/dashboard";
import FindConsultant from "./pages/patient/find-consultant";
import Home from "./pages/home";
import DoctorProfilePatient from "./pages/patient/doctor-profile";
import PatientChat from "./pages/patient/chat";
import PatientAiChat from "./pages/patient/AiChat";
import DoctorChat from "./pages/doctor/chat";
import DoctorDashboard from "./pages/doctor/Dashboard";
import DoctorAccount from "./pages/doctor/account";
import PatientAccount from "./pages/patient/account";
import DoctorProfileDoctor from "./pages/doctor/doctor-profile";
import DoctorVerification from "./pages/doctor/doctor-verification";
import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";
import UnAuthorized from "./pages/UnAuthorized";
import useAuth from "./hooks/useAuth";
import {AppProvider} from "./providers/AppProvider";
import PatientAppointment from "./pages/patient/appointments";
import DoctorAppointment from "./pages/doctor/appointment";
import VideoCall from "./pages/VideoCall";

function App() {

  const {loading} = useAuth();

  return (
    loading ? (<div>Loading...</div>) : (
      <AppProvider>
        <ToastContainer/>
        <Routes>
          {/* Public Routes */}
          <Route path="/auth" element={<Auth/>}/>
          <Route path="/unauthorized" element={<UnAuthorized/>}/>
          <Route path="/" element={<Home/>}/>

          {/* Patient routes */}
          <Route element={<ProtectRoute allowedRoles={['patient']}/>}>
            <Route path="/patient/dashboard" element={<PatientDashboard/>}/>
            <Route path="/patient/find-consultant" element={<FindConsultant/>}/>
            <Route path="/patient/appointment" element={<PatientAppointment/>}/>
            <Route path="/patient/doctor-profile" element={<DoctorProfilePatient/>}/>
            <Route path="/patient/account" element={<PatientAccount/>}/>
            <Route path="/patient/chat" element={<PatientChat/>}/>
            <Route path="/patient/ai-chat" element={<PatientAiChat/>}/>
          </Route>

          {/* Doctor Routes */}
          <Route element={<ProtectRoute allowedRoles={['doctor']}/>}>
            <Route path="/doctor/dashboard" element={<DoctorDashboard/>}/>
            <Route path="/doctor/appointment" element={<DoctorAppointment/>}/>
            <Route path="/doctor/chat" element={<DoctorChat/>}/>
            <Route path="/doctor/account" element={<DoctorAccount/>}/>
            <Route path="/doctor/profile" element={<DoctorProfileDoctor/>}/>
          </Route>

          <Route path="/doctor/verification" element={<DoctorVerification/>}/>

          <Route element={<ProtectRoute allowedRoles={['doctor', 'patient']}/>}>
            <Route path="/video-call" element={<VideoCall/>}/>
          </Route>

          {/* Catch  All */}
          <Route path="*" element={<NotFound/>}/>

        </Routes>
      </AppProvider>
    )


  );
}

export default App;
