import React, { useState } from "react";

export default function VerifyOTP({ setView, axiosPublic, setPasswordResetToken, useCase }) {
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      console.log(useCase);
      if (useCase === "resetPassword") {
        response = await axiosPublic.get("/auth/reset-password/" + otp);
        setPasswordResetToken(response.data.passwordResetToken);
      } else if (useCase === "verifySignup") {
        response = await axiosPublic.put("/users/verify/" + otp);
      }

      setMessage(response.data.message);
      setError("");

      if (useCase === "resetPassword") {
        setView("resetPassword");
      } else if (useCase === "verifySignup") {
        setView("login");
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "Failed to verify OTP. Please try again."
      );
      setMessage("");
    }
  };

  return (
    <div className="p-8 max-w-md w-full space-y-8 pt-8 sm:pt-20 bg-[#007055]">
      <h2 className="mb-6 text-3xl font-bold">Verify OTP</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label className="block">OTP</label>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={handleChange}
            className="w-full py-1 bg-inherit border-b border-white text-white"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-gray-100 rounded text-black hover:bg-gray-500 hover:text-white duration-200"
        >
          Verify OTP
        </button>
        {message && <p className="mt-4 text-green-500">{message}</p>}
        {error && <p className="mt-4 text-red-500">{error}</p>}
        <p className="mt-4 text-center font-light">
          Didn't receive the OTP?{" "}
          <button
            type="button"
            onClick={() => setView("forgot")}
            className="text-blue-500 sm:text-white hover:text-blue-500 font-bold duration-300"
          >
            Resend
          </button>
        </p>
      </form>
    </div>
  );
}
