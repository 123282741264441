import React, { useState, useEffect } from "react";
import TitleDoctor from "../../components/doctor/global/title";
import AppointmentTable from "../../components/user/AppointmentTable";
import useAxios from "../../hooks/useAxios";
import PatientLayout from "../../layouts/patientLayout";

const statusOptions = [
  { label: "All", value: "all" },
  { label: "Pending", value: "pending" },
  { label: "Completed", value: "completed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Rescheduled", value: "rescheduled" },
];

export default function DoctorAppointment() {
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [appointments, setAppointments] = useState([]);
  const { axiosUser } = useAxios();

  const fetchAppointments = async () => {
    try {
      const response = await axiosUser.get("/appointments", {
        params: {
          page: 1,
          limit: 100,
          sortBy: 'createdAt:desc',
          populate: 'doctor,patient',
        },
      });
      setAppointments(response.data.data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [axiosUser]);

  const loadItem = () => {
    fetchAppointments();
  }

  const filteredAppointments = selectedStatus === "all"
      ? appointments
      : appointments.filter((appointment) => appointment.status === selectedStatus);

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
  };

  const updateStatus = async (appointmentId, status) => {
    try {
      await axiosUser.put(`/appointments/${appointmentId}`, {
        'status': status
      });

      loadItem()

      // setAppointments((prevAppointments) =>
      //     prevAppointments.map((appointment) =>
      //         appointment._id === appointmentId ? { ...appointment, status: status } : appointment
      //     )
      // );

      alert('status updated successfully.');
    } catch (error) {
      console.error("Error processing request", error);
    }
  };

  return (
      <PatientLayout>
        <div className="p-4 sm:p-8">
          <div className="flex gap-6 pb-12">
            {statusOptions.map((option) => (
                <button
                    key={option.value}
                    className={`text-[18px] font-bold p-2 bg-white rounded-lg cursor-pointer ${
                        selectedStatus === option.value ? "text-[#007055]" : "text-black"
                    }`}
                    onClick={() => handleStatusFilter(option.value)}
                >
                  {option.label}
                </button>
            ))}
          </div>
          <div className="bg-white px-8 rounded-xl pb-8">
            <section className="py-5">
              <TitleDoctor
                  title={`${
                      selectedStatus === "all"
                          ? "Appointment"
                          : `${selectedStatus.charAt(0).toUpperCase() + selectedStatus.slice(1)} Appointment`
                  }${filteredAppointments.length !== 1 ? "s" : ""}`}
              />
            </section>
            <AppointmentTable
                appointments={filteredAppointments}
                selectedStatus={selectedStatus}
                updateStatus={updateStatus}
                loadItems={loadItem}
            />
          </div>
        </div>
      </PatientLayout>
  );
}
