import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";

const days = [
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
];

function TimeSelectionComponent({ DoctorAvailability }) {

    const {axiosUser} = useAxios();

    const [availability, setAvailability] = useState([
        { day: "Saturday", startTime: "00:00", endTime: "00:00" },
        { day: "Sunday", startTime: "00:00", endTime: "00:00" },
        { day: "Monday", startTime: "00:00", endTime: "00:00" },
        { day: "Tuesday", startTime: "00:00", endTime: "00:00" },
        { day: "Wednesday", startTime: "00:00", endTime: "00:00" },
        { day: "Thursday", startTime: "00:00", endTime: "00:00" },
        { day: "Friday", startTime: "00:00", endTime: "00:00" },
    ]);

    useEffect(() => {
        if (DoctorAvailability && DoctorAvailability.length > 0) {
            console.log(DoctorAvailability)
            setAvailability(prevAvailability =>
                prevAvailability.map(slot => {
                    const doctorSlot = DoctorAvailability.find(item => item.day === slot.day);
                    return doctorSlot ? doctorSlot : slot;
                })
            );
        }
    }, [DoctorAvailability]);

    const handleTimeChange = (day, type, value) => {
        setAvailability(prev =>
            prev.map(slot => (slot.day === day ? { ...slot, [type]: value } : slot))
        );
    };

    const handleSubmit = async () => {
        try{

            const response = await axiosUser.put(
                "/doctors/availability",
                availability.map(({ _id, ...rest }) => rest)
            );

            alert('Availability updated successfully')

        } catch (error) {
            console.error("Failed to update doctor status", error);
        }
    };

    return (
        <div className="max-w-sm mx-auto p-4 sm:p-8">
            <div className="text-[18px] sm:text-[24px] font-bold mb-5 text-center">
                Set Availability
            </div>
            {availability.map(slot => (
                <div key={slot.day} className="my-4">
                    <div className="text-[16px] font-bold">{slot.day}</div>
                    <section className="flex items-center justify-center">
                        <input
                            type="time"
                            className="bg-[#F9F9F9] font-semibold text-[#656565] rounded-lg px-3 py-2"
                            value={slot.startTime || "00:00"}
                            onChange={e => handleTimeChange(slot.day, "startTime", e.target.value)}
                        />
                        <span className="mx-2 text-[14px]">to</span>
                        <input
                            type="time"
                            className="bg-[#F9F9F9] font-semibold text-[#656565] rounded-lg px-3 py-2"
                            value={slot.endTime || "00:00"}
                            onChange={e => handleTimeChange(slot.day, "endTime", e.target.value)}
                        />
                    </section>
                </div>
            ))}
            <button
                className="w-full py-2 bg-[#007055] text-white rounded-lg mt-4 hover:bg-green-700"
                onClick={handleSubmit}
            >
                Apply
            </button>
        </div>
    );
}

export default TimeSelectionComponent;
