import React, {useState} from "react";
import ReactStars from 'react-stars'
import PlaceAppointmentModal from "./PlaceAppointmentModal";
import SendMessageModal from "../SendMessageModal";

const DoctorSuggestions = ({data}) => {

  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const openAppointmentModal = (doctor) => {
    setSelectedDoctor(doctor);
    setIsAppointmentModalOpen(true);
  };

  const openMessageModal = (doctor) => {
    setSelectedDoctor(doctor);
    setIsMessageModalOpen(true);
  };

  const closeModals = () => {
    setIsAppointmentModalOpen(false);
    setIsMessageModalOpen(false);
    setSelectedDoctor(null);
  };

  return (
    <div className="mt-2">
      {data.map((doctor) => (
        <div key={doctor._id} className="border p-1 rounded-lg mb-2 bg-[#D3E4E1]">
          <div className="flex flex-row p-2 justify-between">
            <div className="grow">
              <div className="font-bold text-lg text-[#007055]">Dr. {doctor.user_id.name}</div>
              <div
                className="text-md text-grey font-normal">{doctor.specializations.map(spec => spec.name).join(", ")}
              </div>
              <div
                className="text-md text-grey font-normal">{doctor.qualifications.map(spec => spec.name).join(", ")}
              </div>
            </div>
            <div className="flex-none">
              <div className="flex h-full items-center">
                <div className="flex gap-2">
                  <button
                    onClick={() => openAppointmentModal(doctor)}
                    className="bg-[#007055] text-white rounded-lg px-2 py-2 focus:outline-none hover:bg-[#008A73]">
                    Appointment
                  </button>
                  <button onClick={() => openMessageModal(doctor)}
                    className="bg-black text-white rounded-lg px-2 py-2 focus:outline-none hover:bg-[#007055]">
                    Message
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row p-2 justify-between">
            <div className="rounded-lg text-base font-normal flex">
              <ReactStars count={5} value={doctor.average_rating} edit={false} size={24} color2={'red'}/>
            </div>
            <div className="rounded-lg text-base font-normal">
              <span>Experience:</span> <span className="text-[#2e2e2e]">{doctor.years_of_experience} Years</span>
            </div>
            <div className="text-base font-normal">
              <div>Availability:</div>
              <ul>
                {doctor?.availability.map((avail, index) => (
                  <li key={index} className="text-[#2e2e2e]">
                    {avail.day}: {avail.available ? `${avail.startTime} - ${avail.endTime}` : "Not available"}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
      {selectedDoctor && (
        <>
          <PlaceAppointmentModal
            doctor={selectedDoctor}
            isOpen={isAppointmentModalOpen}
            onClose={closeModals}
          />
          <SendMessageModal
            doctor={selectedDoctor}
            isOpen={isMessageModalOpen}
            onClose={closeModals}
          />
        </>
      )}
    </div>
  );
};

export default DoctorSuggestions;
