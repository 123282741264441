import React, { useState, useEffect } from "react";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import HeaderDoctor from "../../components/doctor/global/header";
import useApp from "../../hooks/useApp";

const DoctorVerification = () => {
  const { axiosUser } = useAxios();
  const location = useLocation();
  const { message = 'Direct access not allowed', applied = true } = location.state || {};
  const { logout } = useApp();

  const [formData, setFormData] = useState({
    biography: "",
    specializations: [],
    qualifications: [],
    years_of_experience: "",
    bmdc_registration_number: "",
  });

  const [options, setOptions] = useState({
    qualifications: [],
    specializations: [],
  });

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [qualificationsResponse, specializationsResponse] = await Promise.all([
          axiosUser.get("/qualifications/all"),
          axiosUser.get("/specializations/all"),
        ]);
        setOptions({
          qualifications: qualificationsResponse.data,
          specializations: specializationsResponse.data,
        });
      } catch (error) {
        console.error("Failed to fetch options", error);
      }
    };
    fetchOptions();
  }, [axiosUser]);

  const handleInputChange = (event) => {
    const { name, value, type, selectedOptions } = event.target;
    if (type === "select-multiple") {
      const values = Array.from(selectedOptions, (option) => option.value);
      setFormData((prev) => ({
        ...prev,
        [name]: values,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosUser.post("doctors/verification", formData);

      alert("Verification request sent successfully");

      logout()
    } catch (error) {
      console.error(error);
      alert(error.response?.data?.message);
    }
  };

  const renderOptions = (options) =>
      options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
      ));

  return (
      <>
        <HeaderDoctor verification="pending" />
        <div className="bg-[#F6F6F6] h-screen py-8 sm:py-12 px-4 sm:px-0">
          <div className="max-w-md mx-auto p-4 sm:p-10 bg-white rounded-xl shadow">
            {applied ? (
                <span>{message}</span>
            ) : (
                <>
                  <div className="font-bold text-lg sm:text-[30px] mb-5">
                    Verification
                  </div>
                  <form onSubmit={handleSubmit}>
              <textarea
                  name="biography"
                  placeholder="Biography"
                  className="w-full p-2 bg-[#F9F9F9] rounded-lg mb-3"
                  value={formData.biography}
                  onChange={handleInputChange}
                  rows="4"
              />
                    <select
                        multiple
                        name="specializations"
                        className="w-full p-2 bg-[#F9F9F9] rounded-lg mb-3"
                        value={formData.specializations}
                        onChange={handleInputChange}
                    >
                      <option value="">Select Specialization</option>
                      {renderOptions(options.specializations)}
                    </select>
                    <select
                        multiple
                        name="qualifications"
                        className="w-full p-2 bg-[#F9F9F9] rounded-lg mb-3"
                        value={formData.qualifications}
                        onChange={handleInputChange}
                    >
                      <option value="">Select Qualification</option>
                      {renderOptions(options.qualifications)}
                    </select>
                    <input
                        type="number"
                        name="years_of_experience"
                        placeholder="Years of Experience"
                        className="w-full p-2 bg-[#F9F9F9] rounded-lg mb-3"
                        value={formData.years_of_experience}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        name="bmdc_registration_number"
                        placeholder="BMDC Registration number"
                        className="w-full p-2 bg-[#F9F9F9] rounded-lg mb-3"
                        value={formData.bmdc_registration_number}
                        onChange={handleInputChange}
                    />
                    <div className="flex items-center justify-end">
                      <button
                          type="submit"
                          className="px-4 py-2 bg-[#007055] text-white text-[18px] font-bold rounded-xl hover:bg-green-700"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </>
            )}
          </div>
        </div>
      </>

  );
};

export default DoctorVerification;
