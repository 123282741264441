import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import useAxios from "../hooks/useAxios";

export const AppContext = createContext(undefined);

export const AppProvider = ({ children }) => {

  const [threadId, setThreadId] = useState(null);
  const [videoCallActive, setVideoCallActive] = useState(false);
  const { setIsLoggedIn, setUserData, setTokenData, tokenData } = useAuth();
  const { axiosUser } = useAxios();
  const navigate = useNavigate();

  const logout = async () => {
    if (tokenData?.refreshToken?.token) {
      try {

        await axiosUser.post('/auth/logout', {
          refreshToken: tokenData.refreshToken.token,
        });

        setIsLoggedIn(false);
        setUserData(null);
        setTokenData(null);
        localStorage.removeItem('userData');
        localStorage.removeItem('tokenData');
        navigate('/auth');

      } catch (error) {
        // Todo: Add toast notification
        console.error(error);
        alert('An error occurred while logging out');
      }
    }
  }

  return (
    <AppContext.Provider value={{
      logout, threadId, setThreadId, videoCallActive, setVideoCallActive
    }}>
      {children}
    </AppContext.Provider>
  );
}