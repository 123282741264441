import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import AgoraRTC, {AgoraRTCProvider} from "agora-rtc-react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {AuthProvider} from "./providers/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
const client = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});

root.render(
  <BrowserRouter>
    <AuthProvider>
      <AgoraRTCProvider client={client}>
        <App />
      </AgoraRTCProvider>
    </AuthProvider>
  </BrowserRouter>
);

reportWebVitals();
